<template>
  <b-card
    id="cardList"
    no-body
  >
    <b-card-header :class="{cardHeaderFull:headerFull}">
      <template v-if="!noBtnMass">
        <b-button-group>
          <template v-if="(($parent.user||{}).role||[]).indexOf('ViewOnly')<=-1">
            <b-button 
              v-if="!noMultiSelect && selectedData.length" 
              variant="danger" 
              v-tooltip="'Hapus Data'"
              @click="doDeleteItemMulti('selected')"
            >
              <i class="ti-trash"></i> 
            </b-button>

            <b-dropdown 
              v-if="
                !noMultiSelect && 
                selectedData.length && 
                statusOptions.length
              "
              right variant="info" 
              v-tooltip="'Ubah Status'"
            >
              <template #button-content>
                <i class="ti-exchange-vertical"></i>
              </template>
              <b-dropdown-item 
                v-for="(v,k) in statusOptions" 
                :key="k" 
                @click="doChangeStatusMulti(v)"
              >
                {{v.name || v.label}}
              </b-dropdown-item>
            </b-dropdown>
          </template>

          <b-dropdown
            right
            toggle-class="absolute-button"
            :disabled="dataList.length == 0"
          >
            <template #button-content>
              <i class="ti-download" v-b-tooltip.hover="'Unduh Data'"></i>
            </template>
            <b-dropdown-item @click="doExport('&exptype=excel')">
              Sebagai Excel
            </b-dropdown-item>
            <b-dropdown-item @click="doExport('&exptype=pdf')">
              Sebagai PDF
            </b-dropdown-item>
          </b-dropdown>
        </b-button-group>

        <slot name="btnleft" />
      </template>
      
      <b-card-title v-if="!headerFull && noBtnMass" style="flex:1">
        <template v-if="dataList">
          {{ data.total > 0 ? `${data.total} data` : '' }}
        </template>
      </b-card-title>

      <div 
        class="filters ml-auto" 
        :class="{'mt-2': headerFull}"
      >
        <b-form @submit.prevent="doFilter()" >
          <b-row :align-h="headerFull?'start':'end'">
            <slot name="filters" />
            <b-col cols="auto">
              <b-input-group>
                <b-input 
                  v-model="filter.search"
                  placeholder="Ketik untuk mencari ..."
                  class="allow-enter"
                />
                <b-input-group-append>
                  <b-button
                    type="submit"
                    variant="info"
                    size="sm"
                    class="btn-icon"
                  >
                    <i class="icon-magnifier"></i>
                  </b-button>
                </b-input-group-append>
                <b-input-group-append>
                  <b-button
                    variant="outline-info reset-btn"
                    size="sm"
                    class="ml-25 reset-btn"
                    @click="doReset(this)"
                  >
                    Reset
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-col>
          </b-row>
        </b-form>
      </div>
    </b-card-header>

    <b-table
      ref="selectTable"

      :primary-key="idKey"
      :fields="fields"
      :items="dataList||[]"

      :per-page="perPage"
      :busy="!dataList||$parent.loadingOverlayTb"

      thead-tr-class="full-color-table full-purple-table"
      :tbody-tr-class="selectedRowClass"

      responsive
      show-empty
    >
      <template #empty>
        <div class="wrap_no_data" align="center">
          <img :src="Gen.baseUrl()+'/images/nodata.svg'" class="nodata-img"/>
          <h3 v-if="filterField.length">
            {{ Config.wording.no_data_search }}
          </h3>
          <h3 v-else>{{ Config.wording.no_data_listed }}</h3>
        </div>
      </template>

      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle mr-3"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>

      <template
        v-if="!noMultiSelect"
        #head(select)
      >
        <!-- SELECT ALL -->
        <b-form-checkbox
          name="select-check"
          v-model="isSelectedAll"
          :indeterminate="isSelectedSebagian"
        />
      </template>

      <template
        v-if="!noMultiSelect"
        #cell(select)="data"
      >
        <!-- SELECT PER DATA -->
        <b-form-checkbox
          v-model="selectedData"
          :value="data.item[idKey]"
          @change="doSelectPerData()"
          name="select-check"
          :ref="`selectManual${data.item[idKey]}`"
        />
      </template>

      <template #cell(number)="v">
        {{(data.per_page*(data.current_page-1))+v.index+1}}
      </template>

      <template 
        :slot="'cell('+slot.key+')'" 
        slot-scope="row" 
        v-for="slot in fieldInCustom"
      >
        <slot :name="slot.key" :scope="row"></slot>
      </template>

      <template 
        :slot="'head('+slot.key+')'" 
        slot-scope="row" 
        v-for="slot in fieldInCustomHead"
      >
        <slot :name="slot.key" :scope="row"></slot>
      </template>

      <template #thead-top>
        <slot name="thead-top"></slot>
      </template>
    </b-table>

    <b-card-footer v-if="!cfNoFooter">
      <template
        v-if="
          (dataList||[]).length &&
          data.total > data.per_page
        "
      >
        <div 
          class="d-flex align-items-center justify-content-between"
        >
          <div class="d-flex align-items-center">
            <b-input-group class="bg-white">
              <template #prepend>
                <b-input-group-text>Show</b-input-group-text>
              </template>
              <b-form-select
                id="perPage"
                class="w-auto mx-50"
                v-model="perPage"
                :options="Config.dataShownOptions"
              />
              <template #append>
                <b-input-group-text>items per page</b-input-group-text>
              </template>
            </b-input-group>
          </div>
          <b-pagination
            class="mb-0"
            v-model="pageNo"
            :per-page="data.per_page"
            :total-rows="data.total"
          />
        </div>
      </template>

      <slot name="footer" />
    </b-card-footer>

  </b-card>
</template>
<script>
import Gen from '@/libs/Gen'
import Config from '@/libs/Config'

let _ = global._
let $ = global.jQuery

export default {
  props: {
    noBtnMass:{
      type:Boolean,
      default:false
    },
    noMultiSelect:{
      type:Boolean,
      default:false
    },
    
    filter:Object,
    idKey:String,
    fields:Array,
    dataListing:{
      type:Object,
      default:()=>{
        return {}
      }
    },
    statusOptions: {
      type: Array,
      default: () => {
        return [
          {id: 1, name: "Active"},
          {id: 2, name: "Inactive"}
        ]
      }
    },

    cfNoFooter: {
      type: Boolean,
      default: false,
    },
    headerFull:{
      type:Boolean,
      default:false
    },
    queryRoute: {
      type:Object,
    }
  },
  computed: {
    filterField(){
      return _.filter(Object.keys(this.filter), (v)=>{return v!='tab'})
    },
    dataList(){return this.data.data },
    pageTitle(){return this.$route.meta?.pageTitle||""},
    fieldInCustom(){
      return _.filter(this.fields, (v)=>{return v.is_custom})
    },
    fieldInCustomHead(){
      return _.filter(this.fields, (v)=>{return v.is_custom_head})
    },

    pageNo: {
      // getter
      get:function() {
        return this.filter.page||this.$route.query.page||1
      },
      // setter
      set:function(newValue) {
        this.$set(this.filter, "page", newValue);
        this.$router.push({
          name:this.$parent.modulePage,
          query:_.clone(this.filter)
        })
      }
    },

    perPage:{
      // getter
      get:function() {
        if(this.$route.name=='PenaksiranMitigasi') return 600000;
        return this.filter.shown||this.$route.query.shown||Config.dataShown
      },
      // setter
      set:function(newValue) {
        this.$set(this.filter, "shown", newValue);
        this.$set(this.filter, "page", 1);
        this.$emit('update:dataListing', {data:false})
        this.$set(this,'data',{data:false})
        this.$router.push({
          name:this.$parent.modulePage,
          query:_.clone(this.filter)
        })
      }
    },
    
    isSelectedAll:{
      get:function(){
        return (
          (this.data.total == this.selectedData.length && this.selectedData.length)||this.selectedAll
        ) ? true : false
      },
      set:function(newValue){
        this.$set(this,'selectedAll',newValue)

        if(newValue){
          this.$set(this,'selectedData',(this.data.all_id||this.$refs.selectTable.items.map(item => item[this.idKey])))
        }else{
          this.$set(this,'selectedData',[])
        }
      }
    },
    isSelectedSebagian(){
      return (this.data.total != this.selectedData.length && this.selectedData.length) ? true : false
    }
  },
  data(){
    return{
      FilterRow:{},
      selectedAll:false,
      selectedData:[],
      exportType:'all',
      data:{data:false},
      Config:Config,
      Gen:Gen,
      exceptMultiOld:['Users'],
    }
  },
  methods:{
    selectedRowClass(item, type) {
      if (!item || type !== 'row') return
      if (this.selectedData.indexOf(item[this.idKey])>-1) return 'table-success'
    },
    doSelectPerData(){
      if(this.selectedAll && !this.selectedData.length){
       this.$set(this,'selectedAll',false) 
      }
    },
    doFilter(){
      if(this.exportType && this.filter){
        let f = Object.values(this.filter).filter((attr)=>attr)
        if(f.length>=1){ 
          this.exportType = 'filtered'
        }else{
          this.exportType = 'all'
        }
      }
      const oldQuery =  JSON.stringify(this.$route.query);
       if(oldQuery != JSON.stringify(this.filter)){
        this.$emit('update:dataListing', {data:false})
        this.$set(this,'data',{data:false})
     } 
      
      this.$router.push({name:this.$route.name,query:Object.assign({},_.clone(this.filter), {page:1})}).catch(()=>{});
    },
    doReset(){
      this.$set(this,'FilterRow',{})
      this.$emit('update:filter', {})

      let link;
      if(this.$route.query.tab){
        link = this.$router.resolve({name:this.$route.name, query:{tab:this.$route.query.tab}});
      }else{
        link = this.$router.resolve({name:this.$route.name});
      }
      
      window.location=link.href
    },
    doChangeStatusMulti(status){
      global.Swal.fire({
        title: `Ingin mengubah status data menjadi ${status.name || status.label}?`,
        icon: 'warning',
        showCancelButton: true,
        cancelButtonColor: '#3085d6',
        confirmButtonText:  `Ya, ${status.name || status.label}!`,
        cancelButtonText: 'Batal',
        customClass: {
          confirmButton: 'btn btn-info',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(res => {
          if(res.value){
            this.$parent.loadingOverlay = true

            if(this.exceptMultiOld.indexOf(this.$parent.modulePage)>-1){

              Gen.apiRest("/do/"+this.$parent.modulePage, {
                data: {
                  type: "status-multi", 
                  status: status.id || status.value,
                  data_id: this.selectedData
                }
              })
                .then(res=> {
                  this.$parent.loadingOverlay = false 
                  
                  this.$parent.apiGet()

                  if('totalFailed' in res.data){
                    global.Swal.fire({
                      title: ` ${res.data.totalUpdated > 0 ? `Berhasil mengubah ${res.data.totalUpdated} data` : `Gagal Mengubah Data`}`,
                      text: res.data.totalUpdated > 0 && res.data.totalFailed <= 0 ? res.data.totalFailed > 0 ? `${res.data.totalFailed} ${res.data.message}` : '' : `${res.data.totalFailed} data tidak dapat diubah karena berelasi data lain`,
                      icon: res.data.totalUpdated > 0 ? 'success' : 'error',
                    })
                  }else {
                    global.Swal.fire({
                      title: `Status data berubah menjadi ${status.name || status.label}.`,
                      icon: 'success',
                    })
                  }

                  this.$set(this,'selectedAll',false)
                  this.$set(this,'selectedData',[])
                })

            }else{
              Gen.apiRest(
                "/do/Selected",
                {
                  data: Object.assign({
                    type: "updateStatus"+this.$parent.modulePage,
                    id: this.selectedData,
                    status: status.id || status.value
                  }, 
                  this.isSelectedAll ? {isSelectedAll : this.isSelectedAll} : {},
                  'risiko' in this.$route.meta ? {risiko_id: this.$route.params.risikoSlug} : {},
                  'led' in this.$route.meta ? { risiko_sebab_id: this.$route.params.ledSlug } : {}
                  )
                },
                "POST"
              )
                .then((res)=>{
                  this.$parent.loadingOverlay = false 
                  this.isSelectedAll = false

                  if('totalFailed' in res.data){
                    global.Swal.fire({
                      title: ` ${res.data.totalUpdated > 0 ? `Berhasil mengubah ${res.data.totalUpdated} data` : `Gagal Mengubah Data`}`,
                      text: res.data.totalUpdated > 0 && res.data.totalFailed <= 0 ? res.data.totalFailed > 0 ? `${res.data.totalFailed} ${res.data.message}` : '' : `${res.data.totalFailed} data tidak dapat diubah karena berelasi data lain`,
                      icon: res.data.totalUpdated > 0 ? 'success' : 'error',
                    })
                  }else {
                    global.Swal.fire({
                      title: `Status data berubah menjadi ${status.name || status.label}.`,
                      icon: 'success',
                    })
                  }

                  setTimeout(() => {
                    this.selectedData = []
                  }, 300)
                  

                  if(this.$route.params.risikoSlug){
                    this.$parent.apiGet({query: {risiko_id:this.$route.params.risikoSlug}})
                    this.$emit('getRisiko')
                  } else if(this.$route.params.ledSlug) {
                    this.$parent.apiGet({query: {led_id:this.$route.params.ledSlug}})
                    this.$emit('getLed')
                  } else {
                    this.$parent.apiGet()
                  }


                })
                .catch(()=>{ 
                  this.$parent.loadingOverlay = false
                })
            }
          }
        })
    },
    doDeleteItemMulti(type){
      global.Swal.fire({
        title: 'Apakah kamu yakin?',
        text: "Data yang telah dihapus tidak dapat dipulihkan kembali!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Ya, Hapus!',
        cancelButtonText: 'Batal',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then((result) => {
        if(result.value){
          this.$parent.loadingOverlay = true
          
          if(this.exceptMultiOld.indexOf(this.$parent.modulePage)>-1){
            Gen.apiRest("/do/"+this.$parent.modulePage, {
              data: {
                type: "delete-multi", 
                data_id: this.selectedData
              }
            })
              .then(res=> {
                this.$parent.loadingOverlay = false 

                this.$parent.apiGet()

                global.Swal.fire({
                  title: ` ${res.data.totalDeleted > 0 ? `Berhasil menghapus ${res.data.totalDeleted} data` : `Gagal Menghapus Data`}`,
                  text: res.data.totalDeleted > 0 && res.data.totalFailed <= 0 ? res.data.totalFailed > 0 ? `${res.data.totalFailed} ${res.data.message}` : '' : `${res.data.totalFailed} data gagal terhapus karena berelasi dengan data lain`,
                  icon: res.data.totalDeleted > 0 ? 'success' : 'error',
                })

                this.$set(this,'selectedAll',false)
                this.$set(this,'selectedData',[])
              })
              .catch(() => {
                this.$parent.loadingOverlay = false 
              })

          }else{
            Gen.apiRest("/do/Selected", {
              data: Object.assign({
                type: "delete"+this.$parent.modulePage, 
                id: this.selectedData, 
                deleteType: type
              }, 
              this.isSelectedAll ? {isSelectedAll : this.isSelectedAll} : {},
              'risiko' in this.$route.meta ? {risiko_id: this.$route.params.risikoSlug} : {},
              'led' in this.$route.meta ? { risiko_detail_id: this.$route.params.ledSlug } : {}
              )
            })
              .then(res=> {
                this.$parent.loadingOverlay = false 
                this.isSelectedAll = false
                global.Swal.fire({
                  title: ` ${res.data.totalDeleted > 0 ? `Berhasil menghapus ${res.data.totalDeleted} data` : `Gagal Menghapus Data`}`,
                  text: res.data.totalDeleted > 0 && res.data.totalFailed <= 0 ? res.data.totalFailed > 0 ? `${res.data.totalFailed} ${res.data.message}` : '' : `${res.data.totalFailed} data gagal terhapus karena berelasi dengan data lain`,
                  icon: res.data.totalDeleted > 0 ? 'success' : 'error',
                })
                
                setTimeout(() => {
                  this.selectedData = []
                }, 300)
                
                if(this.$route.params.risikoSlug){
                  this.$parent.apiGet({query: {risiko_id:this.$route.params.risikoSlug}})
                  this.$emit('getRisiko')
                } else if(this.$route.params.ledSlug) {
                  this.apiGet({query: {led_id:this.$route.params.ledSlug}})
                  this.$emit('getLed')
                } else {
                  this.$parent.apiGet()
                }
              })
              .catch(() => {
                this.$parent.loadingOverlay = false 
              })
          }

        }
      })
    },
    doExport(p){
      let reqUrl = process.env.VUE_APP_API_URL + "/api/bo/get/"+this.$parent.modulePage
      reqUrl += (this.pageSlug?"/"+this.pageSlug:"")
      reqUrl += (this.pageId?"/"+this.pageId:"")
      Object.keys(this.$route.query).forEach((k) => this.$route.query[k] == null && delete this.$route.query[k]);

      let params = Object.assign({export:1, token: Gen.getCookie("bo_auth")||""}, this.$route.query, {order: this.$route.meta.order ? this.$route.meta.order : null}, this.queryRoute || {})
      params = new URLSearchParams(params).toString()

      reqUrl += '?'+params
      if(p) reqUrl += p

      window.open(reqUrl, '_blank')
    },
    doTriggerTable(){
      this.$root.$on('bv::dropdown::hide', () => {
        $(".table-responsive").attr('style', 'overflow:auto')
        $("#cardList").attr('style', 'overflow:auto !important')
      })
      this.$root.$on('bv::dropdown::show', () => {
        $(".table-responsive").attr('style', 'overflow:inherit')
        if((this.dataList||[]).length<=3){
          $("#cardList").attr('style', 'overflow:inherit')
        }else{
          $("#cardList").attr('style', 'overflow:auto')
        }
      })
    }
  },
  mounted(){
    this.doTriggerTable()
  },
  watch:{
    dataListing(v){
      _.forEach(this.$route.query||[], (v2, k2)=>{
        this.$set(this.filter, k2, v2)
      })
      this.$set(this,'data',JSON.parse(JSON.stringify((v))))
      this.doTriggerTable()
    },
    filter(v){
      this.$set(this,'FilterRow',v)
    },
    FilterRow(v,o){
      if(!o) return;
      this.$emit('update:filter', v)
    }
  },
}
</script>
<style scoped>
.card-header{
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color:#fff !important;
}
.cardHeaderFull{display:block !important;}
</style>
<style lang="scss" scoped>
  .card-title{flex: 2;}
  .filters{flex: 4;}
  .activeRow{background: #ffeeb9ed !important}
  @media screen and (max-width: 991px){
    .filters{
      [class^=col]{margin-bottom: .25rem;}
    }
  }
  @media screen and (max-width: 767px) {
    .card-header{
      flex-direction: column;
      align-items: flex-start;
    }
  }
</style>